<template>
  <div class="product-list">
    <van-nav-bar title="我的订单">
      <template #left>
        <router-link to="/product">
          <van-icon name="wap-home-o" size="25px" color="#3a3334" />
        </router-link>
      </template>
    </van-nav-bar>
    <van-tabs v-model="active">
      <van-tab title="全部">
        <div class="card">
          <router-link to="detail">
            <div class="flex">
              <p class="img-box flex fdc jc-c" style="height: 100px">
                <img
                  src="https://mall.s.maizuo.com/86d8414272e50b4b9a7b185ac30dfc86.png"
                  alt=""
                />
              </p>
              <div class="flex fdc jc-sa w100">
                <p class="f14">【山东】金秋红蜜桃</p>
                <div class="flex jc-sb">
                  <span>
                    <span>¥</span>
                    <span class="f16">57.80</span>
                  </span>
                  <span>x 5</span>
                </div>
              </div>
            </div>
          </router-link>
          <div class="footer border-line">
            <p class="flex jc-e">
              <span class="mr-10 f14">共5件商品</span>
              <span class="f14"
                >实付
                <span class="red">¥155.80</span>
              </span>
            </p>
          </div>
          <div class="mt-10 flex jc-sb">
            <span style="color: #bdc0c5; font-size: 15px; line-height: 30px"
              >订单状态:已取消</span
            >
            <van-button
              plain
              hairline
              style="height: 32px"
              color="#181717"
              to=""
              >重新购买</van-button
            >
          </div>
        </div>
        <div style="background: rgb(244, 244, 244); height: 10px"></div>
        <div class="card">
          <router-link to="detail">
            <div class="flex">
              <p class="img-box flex fdc jc-c" style="height: 100px">
                <img
                  src="https://mall.s.maizuo.com/86d8414272e50b4b9a7b185ac30dfc86.png"
                  alt=""
                />
              </p>
              <div class="flex fdc jc-sa w100">
                <p class="f14">【山东】金秋红蜜桃</p>
                <div class="flex jc-sb">
                  <span>
                    <span>¥</span>
                    <span class="f16">57.80</span>
                  </span>
                  <span>x 5</span>
                </div>
              </div>
            </div>
          </router-link>
          <div class="footer border-line">
            <p class="flex jc-e">
              <span class="mr-10 f14">共5件商品</span>
              <span class="f14"
                >实付
                <span class="red">¥155.80</span>
              </span>
            </p>
          </div>
          <div class="mt-10 flex jc-sb">
            <span style="color: #bdc0c5; font-size: 15px; line-height: 30px"
              >订单状态:已取消</span
            >
            <van-button
              plain
              hairline
              style="height: 32px"
              color="#181717"
              to=""
              >重新购买</van-button
            >
          </div>
        </div>
        <div style="background: rgb(244, 244, 244); height: 10px"></div>
      </van-tab>
      <van-tab title="待付款">内容 2</van-tab>
      <van-tab title="待发货">内容 3</van-tab>
      <van-tab title="待取消">内容 4</van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {};
</script>

<style lang="less" scoped>
.card {
  padding: 15px;
  .border-line {
    line-height: 40px;
    height: 40px;
    border-bottom: 1px solid #ece9e9;
    border-top: 1px solid #ece9e9;
  }
}
.img-box img {
  background: #f9f9f9;
  width: 70px;
  height: 70px;
  margin-right: 10px;
}
.red {
  color: red;
}
</style>
